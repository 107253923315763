/*import ("@hotwired/turbo-rails");
Turbo.session.drive = false;*/
//import Rails from "@rails/ujs"
require("./application");

import "../stylesheets/frontend.scss";
import 'bootstrap-icons/font/bootstrap-icons.css'


/* FRONT ASSETS */

import "../vendor/backend/bracket/lib/cookieconsent/dist/cookieconsent";


import AOS from "../vendor/frontend/assets/vendor/aos/aos.js";
window.AOS = AOS;
//import AOS from 'aos';
//require("../vendor/front/assets/vendor/bootstrap/js/bootstrap.bundle.min.js");
import "../vendor/frontend/assets/vendor/glightbox/js/glightbox";
require("../vendor/frontend/assets/vendor/isotope-layout/isotope.pkgd.min.js");
import Swiper from  "../vendor/frontend/assets/vendor/swiper/swiper-bundle.min.js";
window.Swiper = Swiper;

//require("../vendor/frontend/assets/js/main.js");
import "../vendor/frontend/Append/assets/js/main.js";

require("./ticker");
require("./social-share-button");

//import videojs from "./video.min";
import videojs from "video.js";
window.videojs = videojs;
require("./init_videojs");

//require("../vendor/frontend/assets/vendor/mediaelement/build/mediaelement-and-player");
//require("../vendor/frontend/assets/vendor/mediaelement/build/lang/fr");

require("../vendor/frontend/assets/vendor/news-ticker-controls-acme/assets/js/acmeticker");

//require("./init_mediaelement");
require("./cookieconsent_init");
require("./custom");
require("./adblock_notice");
//require("./radio_player");
/*require("./audio_player");*/
//require("./news_feed");
//require("./global_top");

//require("./global-new");
//require("./home");

require("./acme_news_ticker_setup");

//require("./cart");
//require("./pollings");


import toastr from './toastr';
window.toastr = toastr;

//import "@mux/mux-player";
//window.toastr = toastr;

/*import AOS from "../vendor/frontend/assets/vendor/aos/aos.js";
window.AOS = AOS;*/


//require("../vendor/frontend/Append/assets/vendor/lu-js-radioplayer/js/lunaradio.min");
require("../vendor/frontend/Append/assets/vendor/lu-js-radioplayer/js/lunaradio.min");


//require("./init_lunaradio_player");

require("./twbs-toggle-buttons.min");
require("./init_twbs_toggle_buttons");

/* END */
/*
function docReady(fn) {
    // see if DOM is already available
    if (document.readyState === "complete" || document.readyState === "interactive") {
      // call on next available tick
      setTimeout(fn, 1);
    } else {
      document.addEventListener("DOMContentLoaded", fn);
    }
  }
  
  docReady(function () {
    console.log("DDDD");
    let InfiniteScroll = require("infinite-scroll");
  
    var elem = document.querySelector("#matches");
    console.log("MEC: ", elem);
    var infScroll = new InfiniteScroll(elem, {
      // options
      path: ".pagination a[rel=next]",
      append: "row",
      history: false,
      debug: true
    });
  })
    */
