 $(document).on('turbolinks:load', function() {
    
     $(".btn-group-toggle").twbsToggleButtons({

      classActive: "btn-primary",

      classInactive: "btn-outline-primary",
      twbsBtnSelector: "[role='button']"

    });



   
   });